import { TableValues } from 'components/TableInput'

export const successCandidateFields = [
  {
    label: 'Imię i nazwisko kandydata',
    key: 'nominee',
    type: 'input'
  },
  {
    label: 'nazwisko do celu sortowania',
    key: 'sortingName',
    type: 'input'
  },
  {
    label: 'Osoba nominująca',
    key: 'nominatedBy',
    type: 'input'
  },
  {
    label: 'Instytucja nominująca',
    key: 'nominatedByCompany',
    type: 'input'
  },
  {
    label: 'Rola osoby nominowanej w instytucji / organizacji',
    key: 'nomineeRole',
    type: 'input'
  },
  {
    label: 'Założyciel / Założycielka organizacji',
    key: 'founder',
    type: 'boolean'
  },
  {
    label: 'Nazwa firmy',
    key: 'companyName',
    type: 'input'
  },
  {
    label: 'Data założenia firmy',
    key: 'foundingDate',
    type: 'input'
  },
  {
    label: 'Spółka publiczna',
    key: 'publicCompany',
    type: 'boolean'
  },
  {
    label: 'Firma rodzinna',
    key: 'familyCompany',
    type: 'boolean'
  },
  {
    label: 'Akcjonariat / Wspólnicy',
    key: 'partners',
    type: 'input'
  },
  {
    label: 'Branża',
    key: 'industry',
    type: 'input'
  },
  {
    label: 'Zasięg geograficzny',
    key: 'reach',
    type: 'input'
  },
  {
    label: 'Strona www',
    key: 'website',
    type: 'input'
  },
  {
    label: 'Idea innowacji',
    key: 'innovationIdea',
    type: 'input'
  },
  {
    label: 'Opis firmy',
    key: 'companyDescription',
    type: 'wysiwyg'
  },
  {
    label: 'Główne osiągnięcia osoby nominowanej',
    key: 'nomineeAchievements',
    type: 'wysiwyg'
  },
  {
    label: 'Łączna liczba pracowników zatrudnionych',
    key: 'employeeCount',
    type: 'input'
  },
  {
    label: 'Konkurencyjne podmioty na rynku',
    key: 'competition',
    type: 'input'
  },
  {
    label: 'Skala działalności',
    key: 'operationsReach',
    type: 'input'
  },
  {
    label: 'Podobne projekty / działalność na polskim lub zagranicznych rynkach',
    key: 'similarProjects',
    type: 'input'
  },
  {
    label: 'Przewagi konkurencyjne',
    key: 'advantages',
    type: 'wysiwyg'
  },
  {
    label: 'Działalność na rynkach zagranicznych',
    key: 'internationalOperations',
    type: 'boolean'
  },
  {
    label: 'Pozyskane granty',
    key: 'grants',
    type: 'wysiwyg'
  },
  {
    label: 'Działalność społeczna',
    key: 'charityActivities',
    type: 'wysiwyg'
  },
  {
    label: 'Etap rozwoju działalności firmy',
    key: 'developmentStage',
    type: 'input'
  },
  {
    label: 'Wpływ innowacji na rynek / usługi / społeczeństwo',
    key: 'innovationImpact',
    type: 'input'
  },
  {
    label: 'Partnerzy działalności / innowacji',
    key: 'innovationPartners',
    type: 'wysiwyg'
  },
  {
    label: 'Informacje finansowe',
    key: 'financialInfo',
    type: 'table'
  },
  {
    label: 'Komentarze do tabeli',
    key: 'tableComments',
    type: 'wysiwyg'
  },
  {
    label: 'Okoliczności, które miały wpływ na wyniki finansowe',
    key: 'revenueExplained',
    type: 'wysiwyg'
  },
  {
    label: 'Fragmenty artykułów prasowych / internetowych',
    key: 'articleExcerpts',
    type: 'wysiwyg'
  },
  {
    label: 'Czy udało się pozyskać zgodę kandydata na udział w konkursie',
    key: 'consent',
    type: 'boolean-simple'
  },
  {
    label: 'Materiały zostały uzupełnione i zweryfikowane przez partnera merytorycznego konkursu - PWC Polska',
    key: 'collatedBy',
    type: 'boolean-simple'
  }
]

export const innovationCandidateFields = [
  {
    label: 'Imię i nazwisko kandydata',
    key: 'nominee',
    type: 'input'
  },

  {
    label: 'nazwisko do celu sortowania',
    key: 'sortingName',
    type: 'input'
  },
  {
    label: 'Osoba nominująca',
    key: 'nominatedBy',
    type: 'input'
  },
  {
    label: 'Instytucja nominująca',
    key: 'nominatedByCompany',
    type: 'input'
  },
  {
    label: 'Rola osoby nominowanej w instytucji / organizacji',
    key: 'nomineeRole',
    type: 'input'
  },
  {
    label: 'Założyciel / Założycielka organizacji',
    key: 'founder',
    type: 'boolean'
  },
  {
    label: 'Nazwa firmy',
    key: 'companyName',
    type: 'input'
  },
  {
    label: 'Data założenia firmy',
    key: 'foundingDate',
    type: 'input'
  },
  {
    label: 'Spółka publiczna',
    key: 'publicCompany',
    type: 'boolean'
  },
  {
    label: 'Firma rodzinna',
    key: 'familyCompany',
    type: 'boolean'
  },
  {
    label: 'Akcjonariat / Wspólnicy',
    key: 'partners',
    type: 'input'
  },
  {
    label: 'Branża',
    key: 'industry',
    type: 'input'
  },
  {
    label: 'Zasięg geograficzny',
    key: 'reach',
    type: 'input'
  },
  {
    label: 'Strona www',
    key: 'website',
    type: 'input'
  },
  {
    label: 'Idea innowacji',
    key: 'innovationIdea',
    type: 'input'
  },
  {
    label: 'Opis firmy',
    key: 'companyDescription',
    type: 'wysiwyg'
  },
  {
    label: 'Główne osiągnięcia osoby nominowanej',
    key: 'nomineeAchievements',
    type: 'wysiwyg'
  },
  {
    label: 'Łączna liczba pracowników zatrudnionych',
    key: 'employeeCount',
    type: 'input'
  },
  {
    label: 'Konkurencyjne podmioty na rynku',
    key: 'competition',
    type: 'input'
  },
  {
    label: 'Podobne projekty / działalność na polskim lub zagranicznych rynkach',
    key: 'similarProjects',
    type: 'input'
  },
  {
    label: 'Przewagi konkurencyjne',
    key: 'advantages',
    type: 'wysiwyg'
  },
  {
    label: 'Działalność na rynkach zagranicznych',
    key: 'internationalOperations',
    type: 'boolean'
  },
  {
    label: 'Pozyskane granty',
    key: 'grants',
    type: 'wysiwyg'
  },
  {
    label: 'Działalność społeczna',
    key: 'charityActivities',
    type: 'wysiwyg'
  },
  {
    label: 'Etap rozwoju działalności firmy',
    key: 'developmentStage',
    type: 'input'
  },
  {
    label: 'Wpływ innowacji na rynek / usługi / społeczeństwo',
    key: 'innovationImpact',
    type: 'input'
  },
  {
    label: 'Partnerzy działalności / innowacji',
    key: 'innovationPartners',
    type: 'wysiwyg'
  },
  {
    label: 'Informacje finansowe',
    key: 'financialInfo',
    type: 'table'
  },
  {
    label: 'Komentarze do tabeli',
    key: 'tableComments',
    type: 'wysiwyg'
  },
  {
    label: 'Okoliczności, które miały wpływ na wyniki finansowe',
    key: 'revenueExplained',
    type: 'wysiwyg'
  },
  {
    label: 'Fragmenty artykułów prasowych / internetowych',
    key: 'articleExcerpts',
    type: 'wysiwyg'
  },
  {
    label: 'Czy udało się pozyskać zgodę kandydata na udział w konkursie',
    key: 'consent',
    type: 'boolean-simple'
  },
  {
    label: 'Materiały zostały uzupełnione i zweryfikowane przez partnera merytorycznego konkursu - PWC Polska',
    key: 'collatedBy',
    type: 'boolean-simple'
  }
]

export const charityCandidateFields = [
  {
    label: 'Imię i nazwisko kandydata',
    key: 'nominee',
    type: 'input'
  },
  {
    label: 'nazwisko do celu sortowania',
    key: 'sortingName',
    type: 'input'
  },
  {
    label: 'Osoba nominująca',
    key: 'nominatedBy',
    type: 'input'
  },
  {
    label: 'Instytucja nominująca',
    key: 'nominatedByCompany',
    type: 'input'
  },
  {
    label: 'Rola osoby nominowanej w instytucji / organizacji',
    key: 'nomineeRole',
    type: 'input'
  },
  {
    label: 'Założyciel / Założycielka organizacji',
    key: 'founder',
    type: 'boolean'
  },
  {
    label: 'Nazwa organizacji',
    key: 'companyName',
    type: 'input'
  },
  {
    label: 'Data rozpoczęcia działalności',
    key: 'foundingDate',
    type: 'input'
  },
  {
    label: 'Profil działalności',
    key: 'activityProfile',
    type: 'input'
  },
  {
    label: 'Zasięg geograficzny',
    key: 'reach',
    type: 'input'
  },
  {
    label: 'Strona www',
    key: 'website',
    type: 'input'
  },
  {
    label: 'Opis instytucji / organizacji',
    key: 'companyDescription',
    type: 'wysiwyg'
  },
  {
    label: 'Opis działalności',
    key: 'activityDescription',
    type: 'wysiwyg'
  },
  {
    label: 'Główne osiągnięcia osoby nominowanej',
    key: 'nomineeAchievements',
    type: 'wysiwyg'
  },
  {
    label: 'Współpraca z partnerami',
    key: 'partners',
    type: 'wysiwyg'
  },
  {
    label: 'Skala pomocy w ramach działalności / wpływ na otoczenie',
    key: 'activityReach',
    type: 'input'
  },
  {
    label: 'Liczba osób zaangażowanych w pracę i wspieranie instytucji / organizacji',
    key: 'headcount',
    type: 'input'
  },
  {
    label: 'Fragmenty artykułów prasowych / internetowych',
    key: 'articleExcerpts',
    type: 'wysiwyg'
  },
  {
    label: 'Czy udało się pozyskać zgodę kandydata na udział w konkursie',
    key: 'consent',
    type: 'boolean-simple'
  },
  {
    label: 'Materiały zostały uzupełnione i zweryfikowane przez partnera merytorycznego konkursu - PWC Polska',
    key: 'collatedBy',
    type: 'boolean-simple'
  }
]

export interface FormFieldsTypes {
  label: string
  key: string
  type: string
}

export const getFormInitialValues = (fields: FormFieldsTypes[]) => {
  let v = {}

  const getValue = (type: string) => {
    switch (type) {
      case 'boolean':
        return null
      case 'boolean-simple':
        return 'true'
      case 'table':
        return {
          th1: '',
          th2: '',
          th3: '',
          th4: '',
          th5: '',
          td11: '',
          td12: '',
          td13: '',
          td14: '',
          td15: '',
          td21: '',
          td22: '',
          td23: '',
          td24: '',
          td25: '',
          td31: '',
          td32: '',
          td33: '',
          td34: '',
          td35: '',
          td41: '',
          td42: '',
          td43: '',
          td44: '',
          td45: '',
          td51: '',
          td52: '',
          td53: '',
          td54: '',
          td55: '',
        }
      case 'input':
      case 'wysiwyg':
      case 'image':
        return ''
      default:
        return ''
    }
  }
  fields.forEach(field => {
    // @ts-ignore
    v[field.key] = getValue(field.type)
  })

  return v
}

export const findTableSize = (data: TableValues) => {
  let maxColumn = 0
  let maxRow = 0

  for (const key in data) {
    // @ts-ignore
    if (data.hasOwnProperty(key) && data[key] !== '') {
      const [, row, column] = key.match(/td(\d)(\d)/) || []

      if (column && row) {
        maxColumn = Math.max(maxColumn, parseInt(column, 10))
        maxRow = Math.max(maxRow, parseInt(row, 10))
      }
    }
  }

  return { columns: maxColumn, rows: maxRow };
}
