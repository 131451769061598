import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import { IconEye, IconPencil } from '@tabler/icons-react'
import RemoveCandidateModal from 'admin/components/RemoveCandidateModal'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { supabase } from 'utils/supabase'
import toast from 'react-hot-toast'

export interface CandidateTableRowTypes {
  id: string
  sortingName: string
  imageUrl?: string | null
  type: string
  nominee: string
  nominatedBy: string
  nominatedByCompany: string
  tableComments?: string
}

interface Candidates {
  candidates: CandidateTableRowTypes[]
  onRemove: () => void
  slug: string
}

const CandidatesTable = ({ candidates, onRemove, slug }: Candidates) => {
  const navigate = useNavigate()
  const [finalist, setFinalist] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const response = await supabase.from('candidates').select().eq('finalist', true)
        const data = response.data || []

        const finalistState: { [key: string]: boolean } = {}
        data.forEach(candidate => {
          finalistState[candidate.id] = true
        })

        setFinalist(finalistState)
      } catch (err) {
        console.error(err)
      }
    }

    fetchCandidate()
  }, [])

  const toggleFinalistCheckbox = async (id: string) => {
    try {
      const updatedFinalistState = {
        ...finalist,
        [id]: !finalist[id]
      }

      setFinalist(updatedFinalistState)

      await supabase.from('candidates').update({ finalist: !finalist[id] }).eq('id', id)

      toast.success(`Kandydat został ${!finalist[id] ? 'DODANY do' : 'USUNIĘTY z'} listy finalistów`, {
        style: {
          background: !finalist[id] ? '#c59b51' : 'yellow',
          color: !finalist[id] ? '#fff' : 'black',
        },
      })
    } catch (err) {
      toast.error('Wystąpił błąd podczas aktualizacji kandydata')
      console.error(err)
    }
  }
  // const fetchCandidate = async () => {
  //   try {
  //     const response = await supabase.from('candidates').select().eq('finalist', true)
  //     const data = response.data ? response.data[0] : null
  //
  //     //@ts-ignore
  //     const finalistState = data.reduce((acc, curr) => {
  //       acc[curr.id] = true;
  //       return acc;
  //     }, {});
  //
  //     setFinalist(finalistState);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  //
  // useEffect(() => {
  //   fetchCandidate()
  // }, [])
  //
  // const toggleFinalistCheckbox = async (id: string) => {
  //   try {
  //     setFinalist(prevFinalist => ({
  //       ...prevFinalist,
  //       [id]: !prevFinalist[id]
  //     }))
  //
  //     await supabase.from('candidates').update({ finalist: !finalist[id] }).eq('id', id);
  //
  //     toast.custom(`Kandydat został ${!finalist[id] ? 'dodany do' : 'usunięty z'} listy finalistów`, {
  //       style: {
  //         background: !finalist[id] ? '#c59b51' : 'yellow',
  //         color: '#fff',
  //       },
  //     })
  //   } catch (err) {
  //     toast.error('Wystąpił błąd podczas aktualizacji kandydata')
  //     console.error(err)
  //   }
  // }

  const sortedCandidates = candidates
    .slice()
    .sort((a, b) => (a.sortingName > b.sortingName ? 1 : -1))

  return isNotNilOrEmpty(sortedCandidates) ? (
    <Table>
      <thead>
      <tr>
        <th>Nazwa kandydata</th>
        <th>Finalista?</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {sortedCandidates.map(candidate => (
        <tr key={candidate.id}>
          <td>{candidate?.nominee || '---'}</td>
          <td>
            <input
              type='checkbox'
              checked={finalist[candidate.id] || false}
              onChange={() => toggleFinalistCheckbox(candidate.id)}
            />
          </td>
          <td>
            <Actions>
              <Tooltip title='Zobacz podgląd'>
                <IconWrapper onClick={() => {
                  navigate(`/admin/kandydaci/zobacz/${candidate.id}`)
                }}>
                  <IconEye />
                </IconWrapper>
              </Tooltip>
              <Tooltip title='Edytuj'>
                <IconWrapper onClick={() => {
                  navigate(`/admin/kandydaci/${slug}/edytuj/${candidate.id}`)
                }}>
                  <IconPencil />
                </IconWrapper>
              </Tooltip>
              <RemoveCandidateModal id={candidate.id} onRemove={onRemove} />
            </Actions>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  ) : (
    <div>Brak kandydatów w tej kategorii</div>
  )
}

export default CandidatesTable


const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }

  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`
