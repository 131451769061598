import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import AddVoterModal from 'admin/components/AddVoterModal'
import VotersTable from 'admin/components/VotersTable'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'

export interface VoterTypes {
  id: string
  code: string
  name: string
  email: string
  initial_voting_saved: boolean
  voting_saved: boolean
  votes: {
    [key: string]: {
      nominee: string
      score: string
    }
  },
  votes_initial: {
    [key: string]: {
      nominee: string
      value: string
    }
  }
}

const Voters = () => {
  const [voters, setVoters] = useState<VoterTypes[] | []>([])

  const getVoters = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()

      const data = response.data as VoterTypes[]

      setVoters(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getVoters()
  }, [])

  return (
    <AdminPageWrapper>
      <Header>
        <Title>
          Lista członków kapituły
        </Title>
        <AddVoterModal callback={getVoters} />
      </Header>
      <VotersTable voters={voters} onRemove={getVoters} />
    </AdminPageWrapper>
  )
}

export default Voters

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-size: 24px;
`
