import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import { v4 as uuidv4 } from 'uuid'
import toast from 'react-hot-toast'
import { generateRandomCode } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import WysiwygEditor from 'components/WysiwygEditor'
import BooleanInput from 'components/BooleanInput'
import BooleanRichInput from 'components/BooleanRichInput'
import TableInput, { TableValues } from 'components/TableInput'
import { isNotNilOrEmpty } from 'utils/ramda'
import { omit } from 'ramda'
import { getFormInitialValues, successCandidateFields } from 'utils/candidates'
import ImageUploader from 'admin/components/ImageUploader'

const fields = successCandidateFields

export interface SuccessCandidateTypes {
  id: string
  sortingName: string
  code: string
  type: string
  nominee: string
  nominatedBy: string
  nominatedByCompany: string
  nomineeRole: string
  founder: string
  companyName: string
  foundingDate: string
  publicCompany: string
  familyCompany: string
  partners: string
  industry: string
  reach: string
  website: string
  innovationIdea: string
  companyDescription: string
  nomineeAchievements: string
  employeeCount: string
  competition: string
  similarProjects: string
  advantages: string
  internationalOperations: boolean
  grants: string
  charityActivities: string
  developmentStage: string
  innovationImpact: string
  innovationPartners: string
  financialInfo: TableValues
  tableComments: string
  revenueExplained: string
  articleExcerpts: string
  consent: string
  collatedBy: string
  finalist: boolean
  imageUrl?: string | null
}

interface SuccessCandidateFormProps {
  candidate?: SuccessCandidateTypes
}

const SuccessCandidateForm = ({ candidate }: SuccessCandidateFormProps) => {
  const [values, setValues] = useState<Partial<SuccessCandidateTypes>>(getFormInitialValues(fields))
  const navigate = useNavigate()

  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }))
  }

  const handleCustomValueChange = (name: string, value: string | boolean | null | TableValues) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const isEditForm = isNotNilOrEmpty(candidate)

  useEffect(() => {
    candidate && setValues(omit(['id', 'code', 'type'], candidate))
  }, [candidate])

  const handleUploadFile = (url: string) => {
    setValues(prev => ({ ...prev, imageUrl: url }))
  }

  const handleRemoveImage = () => {
    setValues(prev => ({ ...prev, imageUrl: null }))
  }

  const generateFields = () => {
    return (
      <>
        {fields.map(field => {
          if (field.type === 'input') {
            return (
              <TextField
                size='small'
                required
                fullWidth
                // @ts-ignore
                value={values[field.key]}
                onChange={handleValueChange(field.key)}
                label={field.label}
                variant='outlined'
              />
            )
          }

          if (field.type === 'wysiwyg') {
            return (
              <WysiwygEditor
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                // @ts-ignore
                value={values[field.key]}
              />
            )
          }

          if (field.type === 'boolean') {
            return (
              <BooleanRichInput
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                // @ts-ignore
                value={values[field.key]}
              />
            )
          }

          if (field.type === 'boolean-simple') {
            return (
              <BooleanInput
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                // @ts-ignore
                value={values[field.key]}
              />
            )
          }

          if (field.type === 'table') {
            return (
              <TableInput
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                // @ts-ignore
                value={values[field.key]}
              />
            )
          }

          return <div>{field.type}</div>
        })}
      </>
    )
  }

  const handleSubmit = async () => {
    if (isEditForm) {
      try {
        await supabase.from('candidates').update([values]).eq('id', candidate?.id || '')
        toast.success('Pomyślnie zapisano kandydata')
      } catch (err) {
        toast.error('Wystąpił błąd podczas zapisu')
        console.error(err)
      }
    } else {
      const id = uuidv4()
      const payload = {
        id,
        code: generateRandomCode(),
        type: 'success',
        ...values
      }

      try {
        await supabase.from('candidates').insert([payload])
        toast.success('Pomyślnie zapisano kandydata')
        navigate(`/admin/kandydaci/sukces/edytuj/${id}`)
      } catch (err) {
        toast.error('Wystąpił błąd podczas zapisu')
        console.error(err)
      }
    }
  }

  return (
    <>
      <Header>
        <Title>
          {
            isEditForm
              ? 'Edycja kandydata w kategorii SUKCES'
              : 'Nowy kandydat w kategorii SUKCES'
          }
        </Title>
        <Buttons>
          <Button variant='outlined' onClick={() => navigate('/admin/kandydaci')}>Powrót</Button>
          <Button variant='contained' onClick={handleSubmit}>Zapisz</Button>
        </Buttons>
      </Header>
      <FormWrapper>
        <ImageUploader onUpload={handleUploadFile} />
        {values.imageUrl && (
          <>
            <img style={{ width: '200px', height: 'auto' }} src={values.imageUrl} alt='candidate img' />
            <button onClick={handleRemoveImage}>Usuń zdjęcie</button>
          </>
        )}
        {generateFields()}
      </FormWrapper>
    </>
  )
}

export default SuccessCandidateForm

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ql-editor {
    min-height: 100px !important;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})
