import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase';
import toast from 'react-hot-toast'
import { isNotNilOrEmpty } from 'utils/ramda'
import { generateRandomString } from 'utils/common'

interface UploadResponse {
  data: any
  error: Error | null
}

const ImageUploader = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const [selectedFile, setSelectedFile] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    isNotNilOrEmpty(imageUrl) && onUpload(imageUrl)
  }, [imageUrl])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const fileExtensionRe = /.*\.(.*)/.exec(file.name) || []
      const newFileName = `${generateRandomString()}.${fileExtensionRe[1]}`

      const renamedFile = new File([file], newFileName, { type: file.type })

      // @ts-ignore
      setSelectedFile(renamedFile);
    }
  }
  console.log({ selectedFile })

  const uploadImage = async () => {
    try {
      if (!selectedFile) {
        throw new Error('No file selected.')
      }

      const { data, error } : UploadResponse = await supabase
        .storage
        .from('nominees')
        // @ts-ignore
        .upload(selectedFile.name, selectedFile);

      if (error) {
        toast.error('Coś poszło nie tak')
      } else {
        const { data: imageData } = supabase.storage
          .from('nominees')
          .getPublicUrl(data.path)

        if (imageData) {
          setImageUrl(imageData.publicUrl)
        }
      }
    } catch (error) {
      // @ts-ignore
      toast.error('Error uploading image:' + error.message)
    }
  }

  return (
      <div>
        <input type="file" onChange={handleFileChange} />
        <button onClick={uploadImage}>Upload</button>
      </div>
  )
}

export default ImageUploader;
